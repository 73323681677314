import React, { useEffect, useRef, useState } from 'react'
import 'react-image-gallery/styles/css/image-gallery.css'
import OnImagesLoaded from 'react-on-images-loaded'

const Index: React.FC = () => {
  const isoRef = useRef()
  const [isotope, setIsotope] = useState(null)

  const callback = (timer = 500) => {
    setTimeout(function () {
      const isotopeInstance = new Isotope(isoRef.current, {
        percentPosition: true,
        layoutMode: 'masonry',
      })
      setIsotope(isotopeInstance)
    }, timer)
  }

  useEffect(() => {
    if (isotope) {
      isotope.reloadItems()
    }
  })

  return (
    <section className="main-gallery container-px-0 mt-10" id="services">
      <h1 className="title-section text-center">TREATMENTS</h1>
      <OnImagesLoaded
        onLoaded={() => callback()}
        onTimeout={() => callback()}
        timeout={12000}
      >
        <div className="container-fluid">
          <div
            ref={isoRef}
            className="row isotope-wrapper masonry-layout c-gutter-10 c-mb-10"
          >
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item item-gallery text-center ds">
                <div className="item-media h-100 w-100 d-block">
                  <picture>
                    <source
                      srcSet="/images/gallery/26.webp"
                      type="image/webp"
                    />
                    <source srcSet="/images/gallery/26.jpg" type="image/jpeg" />
                    <img
                      src="images/gallery/26.jpg"
                      className="rounded-0"
                      alt="lip fillers"
                    />
                  </picture>
                  <div className="media-links">
                    <a
                      className="abs-link"
                      title="lip fillers"
                      href="/lip-fillers"
                    />
                  </div>
                  <div className="galery-gorizontal gallery-center">
                    <h3>
                      <a
                        className="abs-link"
                        title="lip fillers"
                        href="/lip-fillers"
                      >
                        Lip Fillers
                      </a>
                    </h3>
                    <p>Enhancement</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item item-gallery text-center ds">
                <div className="item-media h-100 w-100 d-block">
                  <picture>
                    <source
                      srcSet="/images/gallery/03.webp"
                      type="image/webp"
                    />
                    <source srcSet="/images/gallery/03.jpg" type="image/jpeg" />
                    <img
                      src="/images/gallery/03.jpg"
                      className="rounded-0"
                      alt="Dermal fillers Cheshunt"
                    />
                  </picture>
                  <div className="media-links">
                    <a
                      className="abs-link"
                      title="Dermal Fillers"
                      href="/dermal-fillers"
                    />
                  </div>
                  <div className="galery-gorizontal">
                    <h3>Dermal Fillers</h3>
                    <p>Natural airbrushed look</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item item-gallery text-center ds">
                <div className="item-media h-100 w-100 d-block">
                  <picture>
                    <source
                      srcSet="/images/gallery/27.webp"
                      type="image/webp"
                    />
                    <source srcSet="/images/gallery/27.jpg" type="image/jpeg" />
                    <img
                      src="/images/gallery/27.jpg"
                      className="rounded-0"
                      alt="Tear Trough Filler"
                    />
                  </picture>
                  <div className="media-links">
                    <a
                      className="abs-link"
                      title="Tear Trough Filler"
                      href="/tear-trough-filer"
                    />
                  </div>
                  <div className="galery-gorizontal gallery-center">
                    <h3>
                      <a
                        className="abs-link"
                        title="Tear Trough Filler"
                        href="/tear-trough-filer"
                      >
                        Tear Trough Filler
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item item-gallery text-center ds">
                <div className="item-media h-100 w-100 d-block">
                  <picture>
                    <source
                      srcSet="/images/gallery/25.webp"
                      type="image/webp"
                    />
                    <source srcSet="/images/gallery/25.jpg" type="image/jpeg" />
                    <img
                      src="/images/gallery/25.jpg"
                      className="rounded-0"
                      alt="Cheek Fillers"
                    />
                  </picture>
                  <div className="media-links">
                    <a
                      className="abs-link"
                      title="Cheek Fillers"
                      href="/cheek-fillers"
                    />
                  </div>
                  <div className="galery-gorizontal gallery-center">
                    <h3>
                      <a
                        className="abs-link"
                        title="Cheek Fillers"
                        href="/cheek-fillers"
                      >
                        Cheek Fillers
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item item-gallery text-center ds">
                <div className="item-media h-100 w-100 d-block">
                  <picture>
                    <source
                      srcSet="/images/gallery/20.webp"
                      type="image/webp"
                    />
                    <source srcSet="/images/gallery/20.jpg" type="image/jpeg" />
                    <img
                      src="/images/gallery/20.jpg"
                      className="rounded-0"
                      alt="Anti-Wrinkle Injections Btx"
                    />
                  </picture>
                  <div className="media-links">
                    <a
                      className="abs-link"
                      title="Anti-Wrinkle Injections Btx"
                      href="/anti-wrinkle-injections-btx"
                    ></a>
                  </div>
                  <div className="galery-gorizontal gallery-center">
                    <h3>
                      <a
                        className="abs-link"
                        title="Anti-Wrinkle Injections Btx"
                        href="/anti-wrinkle-injections-btx"
                      >
                        Anti-Wrinkle Injections
                      </a>
                    </h3>
                    <p>Btx</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item item-gallery text-center ds">
                <div className="item-media h-100 w-100 d-block">
                  <picture>
                    <source
                      srcSet="/images/services/russian_lips.webp"
                      type="image/webp"
                    />
                    <source
                      srcSet="/images/services/russian_lips.jpg"
                      type="image/jpeg"
                    />
                    <img
                      src="/images/services/russian_lips.jpg"
                      className="rounded-0"
                      alt="Russian Lips"
                    />
                  </picture>
                  <div className="media-links">
                    <a
                      className="abs-link"
                      title="Russian Flat Lips"
                      href="/russian-lips"
                    ></a>
                  </div>
                  <div className="galery-gorizontal gallery-center">
                    <h3>
                      <a
                        className="abs-link"
                        title="Russian Flat Lips"
                        href="/russian-lips"
                      >
                        Russian Flat Lips
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item item-gallery text-center ds">
                <div className="item-media h-100 w-100 d-block">
                  <picture>
                    <source
                      srcSet="/images/services/eyebrows_lamination.webp"
                      type="image/webp"
                    />
                    <source
                      srcSet="/images/services/eyebrows_lamination.jpg"
                      type="image/jpeg"
                    />
                    <img
                      src="/images/services/eyebrows_lamination.jpg"
                      className="rounded-0"
                      alt="Eyebrows Lamination"
                    />
                  </picture>
                  <div className="media-links">
                    <a
                      className="abs-link"
                      title="Eyebrows Lamination and tint"
                      href="/eyebrows-lamination-and-tint"
                    />
                  </div>
                  <div className="galery-gorizontal gallery-center">
                    <h3>
                      <a
                        className="abs-link"
                        title="Eyebrows Lamination and tint"
                        href="/eyebrows-lamination-and-tint"
                      >
                        Eyebrows Lamination
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item item-gallery text-center ds">
                <div className="item-media h-100 w-100 d-block">
                  <picture>
                    <source
                      srcSet="/images/services/lash_lift_and_int.webp"
                      type="image/webp"
                    />
                    <source
                      srcSet="/images/services/lash_lift_and_int.jpg"
                      type="image/jpeg"
                    />
                    <img
                      src="/images/services/lash_lift_and_int.jpg"
                      className="rounded-0"
                      alt="Lash Lift adn Tint"
                    />
                  </picture>
                  <div className="media-links">
                    <a
                      className="abs-link"
                      title="Lash lift and tint"
                      href="/lash-lift-and-tint"
                    />
                  </div>
                  <div className="galery-gorizontal gallery-center">
                    <h3>
                      <a
                        className="abs-link"
                        title="Lash Lift and Tint"
                        href="/lash-lift-and-tint"
                      >
                        Lash lift
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item item-gallery text-center ds">
                <div className="item-media h-100 w-100 d-block">
                  <picture>
                    <source
                      srcSet="/images/services/microblading.webp"
                      type="image/webp"
                    />
                    <source
                      srcSet="/images/services/microblading.jpg"
                      type="image/jpeg"
                    />
                    <img
                      src="/images/services/microblading.jpg"
                      className="rounded-0"
                      alt="Microblading"
                    />
                  </picture>
                  <div className="media-links">
                    <a
                      className="abs-link"
                      title="Microblading and Nanoblading"
                      href="/microblading"
                    />
                  </div>
                  <div className="galery-gorizontal gallery-center">
                    <h3>
                      <a
                        className="abs-link"
                        title="Microblading and Nanoblading"
                        href="/microblading"
                      >
                        Microblading
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item item-gallery text-center ds">
                <div className="item-media h-100 w-100 d-block">
                  <picture>
                    <source
                      srcSet="/images/services/Fat-dissolving.webp"
                      type="image/webp"
                    />
                    <source
                      srcSet="/images/services/Fat-dissolving.jpg"
                      type="image/jpeg"
                    />
                    <img
                      src="/images/services/Fat-dissolving.jpg"
                      className="rounded-0"
                      alt="Fat Dissolving"
                    />
                  </picture>
                  <div className="media-links">
                    <a
                      className="abs-link"
                      title="Fat Dissolving"
                      href="/fat-dissolving"
                    />
                  </div>
                  <div className="galery-gorizontal gallery-center">
                    <h3>
                      <a
                        className="abs-link"
                        title="Fat Dissolving"
                        href="/fat-dissolving"
                      >
                        Fat Dissolving
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item item-gallery text-center ds">
                <div className="item-media h-100 w-100 d-block">
                  <picture>
                    <source
                      srcSet="/images/services/factial.webp"
                      type="image/webp"
                    />
                    <source
                      srcSet="/images/services/factial.jpg"
                      type="image/jpeg"
                    />
                    <img
                      src="/images/services/factial.jpg"
                      className="rounded-0"
                      alt="Luxury Dermaplaning"
                    />
                  </picture>
                  <div className="media-links">
                    <a
                      className="abs-link"
                      title="Dermaplaning"
                      href="/dermaplaning"
                    />
                  </div>
                  <div className="galery-gorizontal gallery-center">
                    <h3>
                      <a
                        className="abs-link"
                        title="Luxury Dermaplaning"
                        href="/dermaplaning"
                      >
                        Dermaplaning
                      </a>
                    </h3>
                    <p>Feel luxury touch</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OnImagesLoaded>
    </section>
  )
}

export default Index
