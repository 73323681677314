import { graphql } from 'gatsby'
import React from 'react'

import { TreatmentsQueryQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Gallery from '../components/gallery'
import Appointment from '../components/appointment'

interface Props {
  data: TreatmentsQueryQuery
  location: Location
}

const Index: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'Choose out our aesthetics treatments'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Gallery />
      <h4 style={{ textAlign: 'center' }}>
        And many more options available in our salon. <br /> Please click bellow
        BOOK NOW for more informations:
      </h4>
      <Appointment />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query TreatmentsQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
